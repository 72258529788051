
import React, { useContext, useEffect, useState } from 'react';

import {
  EuiPage,
  EuiPageContent,
  EuiEmptyPrompt,
  EuiPageHeader,
  EuiPageSideBar,
  EuiPageBody,
  EuiText,
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui';
import GoogleLogin from 'react-google-login';
import AppContext from '../Context';
import LoggedInPage from './loggedInPage';
import logo from '../logo.svg'
import AboutUsPage from './aboutUsPage';
import axios from 'axios';
import API from '../Api';

const HomePage = ({content, sideNav }) => {

  const appContext = useContext(AppContext)
  const [userInfo, changeUserInfo] = useState(null)
  const [headerRightSideItems, changeHeaderRightSideItems] = useState(null)
  const [pageContent, changePageContent] = useState(appContext.page)

  const responseGoogle = (response) => {
    if (response.profileObj){
      window.localStorage.setItem('userProfile', JSON.stringify(response.profileObj))
      changeUserInfo(response.profileObj);
    }     

    if (response.tokenId){
      window.localStorage.setItem('userToken', JSON.stringify(response.tokenId))
      appContext.changeUserToken(response.tokenId);
    }
        
    if (response.profileObj && response.tokenId){
      appContext.changeLoggedIn(true)
    }    
  }

  useEffect(()=>{
    console.log(appContext.page)
    switch (appContext.page) {
      case 'about-us':
        changePageContent(<AboutUsPage />)
        break;
    
      default:
        changePageContent(appContext.loggedIn ? 
          <LoggedInPage />
          : 
            <EuiEmptyPrompt 
            title={<span>                
                Login to continue <br/> (it's free!)                
            </span>} 
            body={<GoogleLogin
                clientId="760535124267-kl54atncgldvocda7590qp1ogs94h8to.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
              />
            } 
          />
        )
        break;
    }
  }, [appContext.page, appContext.loggedIn])

  useEffect(()=>{
    
    if (window.localStorage.getItem('userProfile')){
      const userprofile= window.localStorage.getItem('userProfile')
      if (!userprofile==="undefined"){
        changeUserInfo(JSON.parse(userprofile))
      }      
    }
    if (window.localStorage.getItem('userToken')){
      const usertoken= window.localStorage.getItem('userToken')
      if (!usertoken==="undefined"){
        appContext.changeUserToken(JSON.parse(window.localStorage.getItem('userToken')))
      }            
    }
    
  }, [])

  useEffect(()=>{
    if(appContext.userToken){
      axios.get(API.checkLogin, {
        headers: {
          'x-token': appContext.userToken
        }
      }).then(resp=>appContext.changeLoggedIn(resp.data))
    } else if (appContext.loggedIn){
      appContext.changeLoggedIn(false)
    }
  }, [appContext.userToken])

  useEffect(()=>{    
    if (userInfo==null) {
      return
    } 

    appContext.loggedIn = true;

    changeHeaderRightSideItems([
      <EuiAvatar
        size="l"
        name="ProfilePic"
        imageUrl={userInfo.imageUrl}
      />,
      <EuiText>Hello, {userInfo.name}</EuiText>,      
    ])

  },[userInfo, changeHeaderRightSideItems, appContext])

  return (
  <EuiPage paddingSize="none">
    <EuiPageSideBar paddingSize="l" sticky>
      {sideNav}
    </EuiPageSideBar>

    <EuiPageBody panelled>
      <EuiPageHeader
        restrictWidth
        iconType={logo}
        iconProps={{
          size: "xxl"
        }}
        pageTitle="Fundsavr"
        rightSideItems={headerRightSideItems}
      />

      <EuiPageContent
        verticalPosition="center"
        horizontalPosition="center"
        paddingSize="none"
        color="subdued"
        hasShadow={false}
        style={{width:"100%"}}
      >
        <EuiFlexGroup>
          <EuiFlexItem>
            {pageContent}  
          </EuiFlexItem>
        </EuiFlexGroup>            
      </EuiPageContent>
    </EuiPageBody>
  </EuiPage>
)};

export default HomePage;