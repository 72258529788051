import './App.css';
import '@elastic/eui/dist/eui_theme_amsterdam_dark.css';
import HomePage from './components/homePage';
import SideNavigationMenu from './components/sideNavigationMenu';
import AppContext from './Context';
import { useState } from 'react';


function App() {

  const [loggedIn, changeLoggedIn] = useState(false)
  const [userToken, changeUserToken] = useState(null)
  const [pageContent, changePageContent] = useState('login')

  return (
    <div className="App">
      <AppContext.Provider value={{
        loggedIn: loggedIn,
        changeLoggedIn: changeLoggedIn,
        userToken: userToken,
        changeUserToken: changeUserToken,
        page: pageContent,
        changePageContent: changePageContent
      }}>
        <HomePage sideNav={<SideNavigationMenu/>}/>
      </AppContext.Provider>      
    </div>
  );
}

export default App;
