import { EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiInMemoryTable, EuiText } from '@elastic/eui'
import axios from 'axios'
import React, {useContext, useEffect, useState} from 'react'
import API from '../Api'
import AppContext from '../Context'

const LoggedInPage = () => { 

    const appContext = useContext(AppContext);
    const [axiosConfig, changeAxiosConfig] = useState(null)

    useEffect(()=>{
        if (appContext.userToken){
            changeAxiosConfig({
                headers:{
                    'x-token': appContext.userToken
                }
            })
        }        
    },[appContext.userToken])

    const tableColumns = [
        {
          field: 'orig_sym',
          name: 'Selected ETF',
          sortable: true,
        },
        {
            field: 'benchmark',
            name: 'Benchmark',
            sortable: true,
        },
        {
            field: 'proposed_sym',
            name: 'Proposed ETF',
            sortable: true,
        },
        {
            field: 'expense',
            name: 'Expense Comparison',
            sortable: true,
        },
    ]

    const [symbolOptions, changeSymbolOptions] = useState([{
        'label': 'Please Wait...'
    }])
    const [selectedSymbols, changeSelectedSymbols] = useState([])

    const [benchmarkOptions, changeBenchmarkOptions] = useState([{
        'label': 'Please Wait...'
    }])
    const [selectedBenchmarks, changeSelectedBenchmarks] = useState([])

    const [etfs, changeETFs] = useState([])

    useEffect(()=> 
        axiosConfig ? axios.get(API.getSymbols, axiosConfig).then(resp => {
            changeSymbolOptions(resp.data.map(symbol => ({
                'label': symbol
            })))
        }) : null,
    [axiosConfig])

    useEffect(()=> 
        axiosConfig ? axios.get(API.getBenchmarks, axiosConfig).then(resp => {
            changeBenchmarkOptions(resp.data.map(benchmark => ({
                'label': benchmark
            })))
        }) : null,
    [axiosConfig])

    useEffect(()=> {
        if (axiosConfig === null) {
            return
        }
        let requests = []
        
        if (selectedSymbols.length > 0){
            selectedSymbols.forEach(option => {
                let optionSelected = option.label
                requests.push(axios.get(`${API.getBestEtfBySymbol}?symbol=${encodeURIComponent(optionSelected)}`, axiosConfig))
            })  
        }

        if (selectedBenchmarks.length > 0){
            selectedBenchmarks.forEach(option => {
                let optionSelected = option.label            
                requests.push(axios.get(`${API.getBestEtfByBenchmark}?benchmark=${encodeURIComponent(optionSelected)}`, axiosConfig))
            }) 
    
        }
              
                
        if (requests.length > 0){
            let result = []
            axios.all(requests).then(respArray=>{      
                respArray.forEach(resp=>{result = [...result, {
                    orig_sym: resp.data.original.symbol,
                    benchmark: resp.data.original.benchmark,
                    proposed_sym : resp.data.proposed.symbol,
                    expense: `${resp.data.original.expense} vs ${resp.data.proposed.expense}`
                }]})                
                console.log(result)
                changeETFs(result)
            })
        }        
        
    },[selectedSymbols, selectedBenchmarks, axiosConfig])
    

    const handleSymbolChange = (e) => {
        let msg = ""
        if (e.length > selectedSymbols.length){
            msg = `Add ${e[e.length-1].label} to portfolio?`
        } else {
            msg = `Remove from portfolio?`
        }
        if (window.confirm(msg)){
            changeSelectedSymbols(e)
        }
    }

    const handleBenchmarkChange = (e) => {
        let msg = ""
        if (e.length > selectedBenchmarks.length){
            msg = `Add ${e[e.length-1].label} to portfolio?`
        } else {
            msg = `Remove from portfolio?`
        }
        
        if (window.confirm(msg)){
            changeSelectedBenchmarks(e)
        }        
    }

    return <EuiFlexGroup 
                style={{width: "100%"}}
                direction="column"
            >
            <EuiFlexItem>
                <EuiFlexGroup 
                    style={{width: "100%"}}
                    direction="row"
                >
                    <EuiFlexItem>
                        <EuiComboBox
                            style={{
                                paddingTop: 20,
                                paddingLeft: 10
                            }}
                            placeholder="Select or begin to type to search ETFs"
                            options={symbolOptions}
                            selectedOptions={selectedSymbols}
                            onChange={handleSymbolChange}
                            isClearable={true}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiComboBox
                            style={{
                                paddingTop: 20,
                                paddingLeft: 10
                            }}
                            placeholder="Select or begin to type to search Benchmarks"
                            options={benchmarkOptions}
                            selectedOptions={selectedBenchmarks}
                            onChange={handleBenchmarkChange}
                            isClearable={true}
                        />
                    </EuiFlexItem>
                    
                </EuiFlexGroup>
            </EuiFlexItem>
            <EuiHorizontalRule size="half" />
            { selectedSymbols.length >0 || selectedBenchmarks.length >0 ?
            <EuiInMemoryTable
                style={{
                    margin: 10,
                    paddingLeft: 20
                }}
                items={etfs}
                columns={tableColumns}
                pagination={true}
            />
             : <EuiText textAlign="center" style={{padding: 20, paddingBottom: 40}}>
                 Please search or select an ETF symbol/benchmark from either of the search boxes above to begin
            </EuiText>}
            
        </EuiFlexGroup>
}

export default LoggedInPage