
import React, { useContext, useState } from 'react';

import { EuiSideNav } from '@elastic/eui';
import { htmlIdGenerator } from '@elastic/eui/lib/services';
import AppContext from '../Context';

const SideNavigationMenu = () => {
  const appContext = useContext(AppContext)
  const [isSideNavOpenOnMobile, setisSideNavOpenOnMobile] = useState(false);

  const toggleOpenOnMobile = () => {
    setisSideNavOpenOnMobile(!isSideNavOpenOnMobile);
  };

  const sideNav = [
    {
      name: 'Menu',
      id: htmlIdGenerator('sideMenu')(),
      items: [
        {
          name: 'Dashboard',
          id: htmlIdGenerator('dashboard')(),
          onClick: () => appContext.changePageContent('dashboard'),
        },
        {
          name: 'About Us',
          id: htmlIdGenerator('aboutus')(),
          onClick: () => appContext.changePageContent('about-us'),
        },
      ],
    },
  ];

  return (
    <EuiSideNav
      toggleOpenOnMobile={() => toggleOpenOnMobile()}
      isOpenOnMobile={isSideNavOpenOnMobile}
      style={{ width: 192 }}
      items={sideNav}
    />
  );
};

export default SideNavigationMenu;