import { createContext } from 'react';

const AppContext = createContext({
  loggedIn: null,
  changeLoggedIn: null,
  userToken: null,
  changeUserToken: null,
  page: null,
  changePageContent: null
});

export default AppContext