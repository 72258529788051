const APIConfig = {
    'backend': 'https://etf-tracker-backend.davidvassallo.me' // 'http://localhost:8000'//
}

const API = {
    'getBenchmarks': `${APIConfig.backend}/getBenchmarks`,
    'getSymbols': `${APIConfig.backend}/getSymbols`,
    'getBestEtfByBenchmark': `${APIConfig.backend}/getBestEtfByBenchmark`,
    'getBestEtfBySymbol': `${APIConfig.backend}/getBestEtfBySymbol`,
    'checkLogin': `${APIConfig.backend}/checkLogin`,

}

export default API