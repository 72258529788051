import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from '@elastic/eui'
import React from 'react'

const AboutUsPage = () => { 

    return <EuiFlexGroup 
                style={{width: "100%"}}
                direction="column"
            >
            <EuiFlexItem>
                About Us
            </EuiFlexItem>
            <EuiHorizontalRule size="half" />    
        </EuiFlexGroup>
}

export default AboutUsPage